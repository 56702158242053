<template>
<button><slot/></button>
</template>

<script setup>
</script>

<style scoped>
button {
  border-radius: 15px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: var(--foreground);
  color: var(--text);
  cursor: pointer;
  transition: background-color 0.25s;
}
button:hover {
  background-color: var(--accent);
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}
</style>
