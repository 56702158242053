<template>
  <main>
    <h1>Call for proposals</h1>

    <p>
    The TEI Steering Committee (TEI SC) welcomes proposals for hosting and organizing future TEI conferences. TEI is an ACM sponsored conference and is typically held in between late January and early March. TEI attracts an interdisciplinary audience from HCI, engineering, design and the arts and provides a platform for discussion and meeting people. We value diversity and aim to learn from other perspectives.
    </p>
    <p>
    As a custom, TEI is a single-track conference (i.e. no parallel presentation sessions). TEI has a number of submission tracks including papers, pictorials, works-in-progress, arts submissions, graduate student consortium and student design challenge and can host various presentation styles (talks, posters, demos, arts exhibit and/or performances). We also value hands-on activities in the form of studios and/or workshops, which are an integral part of the conference and a good place to meet others and learn TEI-skills at the same time.
    Proposals should address the following issues:
    </p>
    <ul>
      <li>
      People involved in the bid and their roles (at minimum the general chair and the program chairs). Please include a short bio for core people and highlight involvement in previous TEI conferences and with the TEI community
      </li>
      <li>
    Commitment to publish the proceedings with ACM and to apply for ACM sponsorship
      </li>
      <li>
    Organizational support at the hosting location
      </li>
      <li>
    Proposed location and details about a possible venue
      </li>
      <li>
    Potential time(s) when the conference can be held
      </li>
      <li>
    Estimated time for issuing the CfP, availability of a website, submission deadlines and registration deadlines
      </li>
      <li>
    Draft conference program
      </li>
      <li>
    Budget overview, including expected revenues from registration fees and sponsors and expected expenses (venue, catering, etc.)
      </li>
      <li>
    Local organization and supporting organizations
      </li>
      <li>
    Arguments for why the conference as proposed by you will be attractive to the TEI community (e.g. accessibility, international rotation, location, relation to specific sub-communities, ability to attract both new and ‘old’ conference visitors)
      </li>
      <li>
    Likely sponsors
      </li>
      <li>
    Any direction/theme change you would envisage and any new features/tracks you would like to include
      </li>
    </ul>

    <p>
    Anyone interested in hosting a future TEI, please contact the TEI Steering Committee first with a short and simple draft, before writing full proposals. The TEI SC are keen to answer any questions, help develop the proposal and mentor potential future chairs through the process. Please submit full bids for TEI 2026 before <b>1 August 2024</b>. Bids for TEI 2027 should be provided before <b>1st of October 2024</b>.
    </p>
    <p>
    The TEI Steering Committee will then take a decision within approximately two months time.
    </p>

    <p>
    Please email your proposal as a PDF document to <a href="mailto:sc@tei.acm.org">sc@tei.acm.org</a>
    </p>
  </main>
</template>

<script setup>
</script>

<style scoped>

</style>
