<template>
<div class="footer">
  <div class="break-line"/>
  <div class="infos">
    <h2>Conference Information</h2>
    <img src="@/assets/tei_logo_small.svg">
    <p>04-07 March 2025, Bordeaux, France</p>
    <p>19th International Conference on Tangible Embedded and Embodied Interaction</p>
    <div class="socials" v-if="false">
      <a href=""><unicon name="twitter"/></a>
      <a href=""><unicon name="facebook-f"/></a>
    </div>
  </div>
  <div v-if="false">
    <h2>Partners</h2>
    <div>
      logo1
    </div>
  </div>
  <div>
    <h2>Sponsors</h2>
    <div>
      <img src="@/assets/acm_k_flat_vtag_rev.png">
      <img src="@/assets/sigchi-logo-full-1568x400.png">
    </div>
  </div>
</div>
</template>

<script setup>
</script>

<style scoped>
.break-line {
  width: 80vw;
  height: 4px;
  background: var(--textlight);
  grid-column: span 3;
  justify-self: center;
  border-radius: 5px;
}

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: var(--background);
  color: var(--text);
}

img {
  height: 60px;
}

@media (min-width: 768px) {
  .footer {
    display: grid;
    grid-template-columns: calc(100%/2) calc(100%/2);
    margin: auto;
  }
}

@media (prefers-color-scheme: light) {
  img {
    filter: invert(1);
  }
}
</style>
