<template>
  <main>
    <h1>Demo</h1>
  </main>
</template>

<script setup>
</script>

<style scoped>
table {
  max-width: 500px;
}

</style>
