<template>
  <main>
    <h1>Lasting impact awards</h1>
    <p>The lasting impact award is given to an article that has made an impact over a decade of research.</p>

    <ul>
      <li>
        <b>TEI 2024 - 10 year award</b><br>
        <span>
          Jifei Ou, Lining Yao, Daniel Tauber, Jürgen Steimle, Ryuma Niiyama, Hiroshi Ishii. 2014.
          <a href="https://dl.acm.org/doi/10.1145/2540930.2540971">jamSheets: thin interfaces with tunable stiffness enabled by layer jamming</a>
        </span>
      </li>
      <li>
        <b>TEI 2023 - 10 year award</b><br>
        <span>
          Tanja Döring, Axel Sylvester, and Albrecht Schmidt. 2013.
          <a href="https://doi.org/10.1145/2460625.2460637">A design space for ephemeral user interfaces</a>
        </span><br>

        <b>TEI 2023 - delayed award for 2008</b><br>
        <span>
          Lesley Xie, Alissa N. Antle, Nima Motamedi. 2008.
          <a href="https://dl.acm.org/doi/10.1145/1347390.1347433">Are tangibles more fun?: comparing children's enjoyment and engagement using physical, graphical and tangible user interfaces</a>
        </span>
      </li>
      <li>
        <b>TEI 2022 - 10 year award</b><br>
        <span>
          Beginner's Mind Collective and David Shaw. 2012.
          <a href="https://doi.org/10.1145/2148131.2148219">Makey Makey: improvising tangible and nature-based user interfaces</a>
        </span>
      </li>
      <li>
        <b>TEI 2021 - 10 year award</b><br>
        <span>
          Hannah Perner-Wilson, Leah Buechley, and Mika Satomi. 2011.
          <a href="https://dl.acm.org/doi/10.1145/1935701.1935715">Handcrafting textile interfaces from a kit-of-no-parts</a>
        </span>
      </li>
      <li>
        <b>TEI 2017 - 10 year award</b><br>
        <span>
          Martin Kaltenbrunner and Ross Bencina. 2007.
          <a href="https://doi.org/10.1145/1226969.1226983">ReacTIVision: a computer-vision framework for table-based tangible interaction</a>
        </span>
      </li>
    </ul>
  </main>
</template>

<script setup>
</script>

<style scoped>

</style>
